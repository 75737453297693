"use client";

import { motion } from "framer-motion";
import { Button } from "../components/ui/button";
import {
  LayoutDashboard,
  UserPlus,
  BookPlus,
  ClipboardList,
  LogOut,
} from "lucide-react";

const sidebarItems = [
  { icon: LayoutDashboard, label: "Dashboard", href: "/dashboard" },
  { icon: BookPlus, label: "Add Subject", href: "/AddSubject" },
  { icon: BookPlus, label: "Class Management", href: "/ClassManagement" },
  { icon: BookPlus, label: "Course Management", href: "/CourseManagement" },
  { icon: BookPlus, label: "Add Marks", href: "/StudentReportCardForm" },
  { icon: BookPlus, label: "Manage Marks", href: "/managemarks" },
  { icon: UserPlus, label: "Student Management", href: "/StudentManagement" },
  {
    icon: ClipboardList,
    label: "Individual Student Report",
    href: "/ReportForm",
  },
  {
    icon: ClipboardList,
    label: "Student Report",
    href: "/MonthlyReport",
  },
];

export function SideNavbar() {
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    window.location.replace("/");
  };

  return (
    <aside className="bg-red-600 text-white h-screen flex flex-col">
      <div className="flex items-center justify-center mb-8">
        <img src="/logo.png" alt="Logo" className="w-auto h-16" />
      </div>
      <nav className="flex-1">
        <ul className="space-y-2">
          {sidebarItems.map((item, index) => (
            <motion.li
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Button
                variant="ghost"
                className="w-full justify-start text-white hover:bg-red-700"
                asChild
              >
                <a href={item.href}>
                  <item.icon className="mr-2" />
                  <span>{item.label}</span>
                </a>
              </Button>
            </motion.li>
          ))}
        </ul>
      </nav>
      {/* Logout Button */}
      <Button
        variant="ghost"
        className="mt-auto text-white hover:bg-red-700"
        onClick={handleLogout}
      >
        <LogOut className="mr-2" />
        Logout
      </Button>
    </aside>
  );
}

export default SideNavbar;
